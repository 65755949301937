import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import ProgressiveImage from "react-progressive-graceful-image";
// import Marquee from "react-fast-marquee";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../../common/Footer";
import AvailableOnMobile from "../../common/available-on-mobile";
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";

// Images imported from assets
import icClientTBZ from "../../assets/img/tbz-logo.jpg";
import icClientMahindraDiamond from "../../assets/img/mahindra-brother-diamonds.svg";
import icClientDream11 from "../../assets/img/dream11-logo.svg";
import icClientMPL from "../../assets/img/mpl-logo.svg";
import icClientNoise from "../../assets/img/noise-logo.svg";
import icClientPidilite from "../../assets/img/pidilite-logo.svg";
import icClientRaymond from "../../assets/img/raymond-logo.svg";
import icClientAparIndus from "../../assets/img/apar-industries-logo.svg";
import icClientNazarTech from "../../assets/img/nazara-technologies-logo.svg";
import icClientNetwork18 from "../../assets/img/Network_18_Logo.svg";
import clientGodrej from "../../assets/img/Godrej_Logo.png";
import icClientTVSMotor from "../../assets/img/TVS_Motor_logo.svg";
import icClientBata from "../../assets/img/Bata.svg";

import heroTopCard from "../../assets/img/hero-top-card.png";
import heroRightCard from "../../assets/img/hero-right-card.png";
import growBusiness from "../../assets/img/grow-business.png";
import vendorPayoutImage from "../../assets/img/vendor-payout.png";
import certifiedBy from "../../assets/img/certified-by-1.svg";
import poweredByYesBank from "../../assets/img/powered-by-yes-bank.png";
import poweredByUnity from "../../assets/img/powered-by-unity.png";

import poweredByInduslndBank from "../../assets/img/powered-by-indusland-bank.svg";
// import poweredByVisa from "../../assets/img/powered-by-visa.svg";
import poweredByMastercard from "../../assets/img/Mastercard_Logo.svg";
import poweredBySBI from "../../assets/img/SBI_Card_Logo.svg";
import poweredByVISA from "../../assets/img/Visa_logo.svg";
import poweredByNPCI from "../../assets/img/NPCI_Logo.svg";

import overviewFrame from "../../assets/img/overview.png";
import directions from "../../assets/img/directions.svg";
import creditCard from "../../assets/img/credit-card.png";
import cardCorner from "../../assets/img/card-corner.png";
import vendorPayments from "../../assets/img/vendor-payments.png";
import vendorPaymentsMobile from "../../assets/img/vendor-payments-mobile.png";

import heroTopCardLazy from "../../assets/img/webp/hero-top-card.webp";
import heroRightCardLazy from "../../assets/img/webp/hero-right-card.webp";
import overviewFrameLazy from "../../assets/img/webp/overview.webp";
import creditCardLazy from "../../assets/img/webp/credit-card.webp";
import cardCornerLazy from "../../assets/img/webp/card-corner.webp";
import vendorPaymentsLazy from "../../assets/img/webp/vendor-payments.webp";
import vendorPaymentsMobileLazy from "../../assets/img/webp/vendor-payments-mobile.webp";

import { Helmet } from "react-helmet";

const Landing = () => {
  const virtual = useRef(null);
  document.title = "Tijoree";
  let descMeta = document.querySelector('meta[name="description"]');
  descMeta.setAttribute(
    "content",
    "A B2B payments platform to optimize all of your business spends that combines the power of commercial credit cards and customisable tech to help you optimise your treasury returns and working capital."
  );
  const owlRef = useRef(null);
  let path = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    if (path?.state?.id) {
      const elements = document.getElementById(path?.state?.id);
      setTimeout(() => {
        elements.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
      navigate({ ...path, state: null });
    }
  }, [path?.state?.id]);

  const owlResponsiveOption = {
    0: {
      items: 1,
    },
    676: {
      items: 2,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    1600: {
      items: 4,
    },
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    AOS.init({
      easing: "ease-out-back",
      duration: 900,
      disable: "mobile",
    });
  }, []);

  const handleCarouselchanged = (e) => {
    // console.log('eeeeeeee',e);
    if (!e.namespace) {
      return;
    }
    const carousel = e.relatedTarget;
    $("#owl-slider-counter .current").text(
      carousel.relative(carousel.current() + 1)
    );

    $("#owl-slider-counter .total").text("/" + carousel.items().length);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("frameVideo");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleOwlNav = (type) => {
    if (type === "NEXT") {
      owlRef.current.next(500);
    } else if (type === "PREV") {
      owlRef.current.prev(500);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title> Tijoree</title>
        <meta
          name="description"
          content="A B2B payments platform to optimize all of your business spends that combines the power of commercial credit cards and customisable tech to help you optimise your treasury returns and working capital."
        />
      </Helmet>
      <div className="page-wrapper">
        <section id="home-main-section">
          {/* <picture>
            <source type="image/webp" srcSet={heroTopCardLazy}></source>
            <source type="image/png" srcSet={heroTopCard}></source>
            <img className="topcard" alt="SUPER-CHARGE YOUR BUSINESS FINANCES" src={heroTopCard} />
          </picture> */}
          <picture>
            <source type="image/webp" srcSet={heroRightCardLazy}></source>
            <source type="image/png" srcSet={heroRightCard}></source>
            <img
              className="rightcard"
              alt="SUPER-CHARGE YOUR BUSINESS FINANCES"
              src={heroRightCard}
            />
          </picture>
          <div className="container-md">
            <div className="row justify-content-lg-center">
              <div className="col-lg-12 herotext text-center">
                <h1>STREAMLINE YOUR BUSINESS PAYABLES WITH TIJOREE</h1>
                <p>
                  Earn monetary benefits and seamlessly manage your central
                  procurement expenses by making your business payments through
                  our corporate credit card platform
                </p>
                <div className="btns-group">
                  <Link className="button btn-yellow" to="/form">
                    Get Started
                  </Link>
                </div>
              </div>
              {/* <div className="col-lg-12 text-center nofee">
                <ul>
                  <li>No Subscription Fee</li>
                  <li><span className='d-lg-block d-md-none d-sm-none'>₹0 Non Maintenance Charges</span> <span className='d-lg-none'>₹0 NMC</span></li>
                  <li>₹0 Transaction Fees</li>
                </ul>
              </div> */}

              <div className="col-lg-12 text-center certified-by">
                <img className="my-4" src={certifiedBy} alt="Certified By" />
              </div>

              <div className="text-center poweredBy pt-lg-5">
                <p>Powered By</p>
                <div className="d-flex flex-wrap justify-content-center gap-4 container">
                  <div className="">
                    <img  src={poweredBySBI} alt="Powered By SBI" />
                  </div>
                  <div className="">
                    <img  src={poweredByVISA} alt="Powered By Visa" />
                  </div>
                  <div className="">
                    <img  src={poweredByMastercard} alt="Powered By Mastercard" />
                  </div>
                  <div className="">
                    <img
                      
                      src={poweredByInduslndBank}
                      alt="Powered By Indusland Bank"
                    />
                  </div>
                  <div className="">
                    <img
                    
                    src={poweredByNPCI} alt="Powered By NPCI" />
                  </div>
                  
                </div>

              </div>
            </div>
          </div>
        </section>

        {/* Our client logo */}
        <section className="our-client-logo-section3">
          <div className="d-flex justify-content-center">
            <div className="col-lg-4 text-center">
              <h2>Our Clients</h2>
            </div>
          </div>

          <div className="integration-box row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={300}
                    height={150}
                    src={icClientMahindraDiamond}
                  />
                </div>
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientDream11}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientMPL}
                  />
                </div>
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientBata}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientPidilite}
                  />
                </div>
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientRaymond}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientAparIndus}
                  />
                </div>
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={clientGodrej}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row">
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientNetwork18}
                  />
                </div>
                <div className="col-6">
                  <img
                    className="mx-auto d-block mb-3"
                    alt=""
                    width={150}
                    height={150}
                    src={icClientTVSMotor}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row align-items-center" style={{height: 150}}>
              <p className="h4 fw-normal text-black text-center mb-3"><b>& 30+ Large Listed Corporates</b></p> 
              </div>
            </div>
          </div>
        </section>

        {/* Corporate Credit Card */}
        <section id="business-cc" className="business-cc">
          <div className="container-md">
            <div className="main-head text-center">
              <div className="head-icon cc-icon"></div>
              <h2>Most Customized Credit Card for your Business</h2>
              <p className="head-sub">Corporate Credit Card</p>
            </div>
            <div className="purple-gradient-box hover-box">
              <div className="inner-box">
                <div className="content-box">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="size40">
                        Tailor our Card to your Company Size & Liquidity Profile
                      </h3>
                      <p className="size20" style={{ opacity: 0.75 }}>
                        Double your treasury earnings by ‘pre-funding’ our card
                        to efficiently use liquidity, or achieve lower borrowing
                        costs with our credit-based solutions
                      </p>
                      <Link
                        className="button btn-white"
                        to="/form"
                        style={{ fontWeight: "bold" }}
                      >
                        Get your Credit Card
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={creditCardLazy}
                        ></source>
                        <source type="image/png" srcSet={creditCard}></source>
                        <img
                          className="credit hover-img"
                          alt="Business with our Credit Card"
                          src={creditCard}
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <section id="cards">
          <div className="container-md">
            <div className="cards">
              <div className="row">
                <div className="col-md-4">
                  <div className="card card-1 hover-box">
                    <img className="hover-img"
                      src={earnInterestImg}
                      alt="Earn Interest"
                    />
                  </div>
                  <p>*Earn up to 7% interest on your AMB</p>
                </div>
                <div className="col-md-4">
                  <div className="card card-2 hover-box">
                    <img width="120" className="hover-img"
                      src={securityCertified}
                      alt="Security Certified"
                    />
                    <p>Ac id condimentum ipsum fermentum praesent. Dui neque mi condimentum pretium sagittis. Risus nunc tempor aliquam.</p>
                  </div>
                  <p>Security Certified</p>
                </div>
                <div className="col-md-4">
                  <div className="card card-3 hover-box">
                    <p>Ac id condimentum ipsum fermentum praesent. Dui neque mi condimentum pretium sagittis. Risus nunc tempor aliquam.</p>
                    <img className="coupon-img hover-img"
                      src={couponCard}
                      alt="Coupon"
                    />
                    <div className="giftbox hover-img">
                      <img
                        src={gift}
                        alt="gift"
                      />
                    </div>
                  </div>
                  <p>Exclusive Rewards</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        {/* 
          <section id="know-about-pos" className="know-about-pos">
          <div className="container-md">
            <div className="row">
              <div className="col-md-4">
                <div className="card card-1">
                  <h2>Low</h2>
                  <h4>MDR Charges</h4>
                  <p>Massa blandit ultricies bibendum accumsan vitae quam scelerisque id.</p>
                </div>
                <div className='poslink'><Link className="about-pos-link" to={'/'}>Know About POS</Link></div>

              </div>
              <div className="col-md-4">
                <div className="card card-2">
                  <h2>No</h2>
                  <h4>Subscription Fee</h4>
                  <p>Massa blandit ultricies bibendum accumsan vitae quam scelerisque id.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-3">
                  <h2><span className="currency">₹</span>0 <span className="sub">NMC</span></h2>
                  <p>Massa blandit ultricies bibendum accumsan vitae quam scelerisque id.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      */}

        {/*
        <section id="make-bulk-payment" className="bulk-payment">
          <div className="container-md">
            <div className="row">
              <div className="col-md-6">
                <div className="grey-pattern-full hover-box p-0">
                  <img className="hover-img desktop"
                    src={transactionSuccess}
                    alt="Make Payments in Bulk"
                  />
                  <img className="hover-img mobile"
                    src={transactionSuccessMobile}
                    alt="Make Payments in Bulk"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h4>Make Payments in Bulk</h4>
                <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien. Morbi fermentum enim ut ultrices sapien diam feugiat.</p>
                <Link className="button btn-purple medium" to="/">Know More</Link>
              </div>
            </div>
          </div>
        </section>
      */}
        {/*
        <section id="payment-reminder-steps" className="payment-reminder-steps">
          <div className="container-md">
            <div className="outer-box grey-pattern-full">
              <div className="inner">
                <ul>
                  <li>
                    <div className="hover-box"><img className="hover-img"
                      src={solarServer}
                      alt="Solar server"
                    /></div>
                    <p class="d-md-none">Update Debtor’s List</p>
                  </li>
                  <li>
                    <div className="hover-box">
                      <img className="hover-img"
                        src={solarFile}
                        alt="Solar file"
                      />
                    </div>
                    <p class="d-md-none">Upload Invoice</p>
                  </li>
                  <li>
                    <div className="hover-box">
                      <img className="hover-img"
                        src={solarCalendar}
                        alt="Solar Calendar"
                      />
                    </div>
                    <p class="d-md-none">Send Reminder</p>
                  </li>
                  <li>
                    <div className="hover-box">
                      <img className="hover-img"
                        src={solarRupee}
                        alt="Solar Calendar"
                      />
                    </div>
                    <p class="d-md-none">Get Paid</p>
                  </li>
                </ul>
                <div className="row bottom-content">
                  <div className="col-md-4">
                    <h4>Send Payment Reminders to your Customers</h4>
                  </div>
                  <div className="col-md-5">
                    <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.
                      Morbi fermentum enim ut ultrices sapien diam feugiat.</p>
                  </div>
                  <div className="col-md-3">
                    <Link className="button btn-white medium" to="/">Try Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        <section id="virtual-cards" className="virtual-cards">

          <div className="container-md">
            <div className="card-group">
              <div className="row">
                <div className="col-md-7">
                  <h3 className="size40">Deploy Virtual Cards</h3>
                  <p className="size20">
                    Simplify online payments with Virtual Cards. Create secure,
                    one-time use digital cards for safer and smoother online
                    transactions. Manage these in the same centralized payables
                    platform with Tijoree
                  </p>
                  <p className="d-sm-block d-lg-none d-md-none">
                    <Link className="button btn-white" to="/form">
                      Know More
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div id="vendorPayments">
          <section id="monitor-spending" className="monitor-spending">
            <div className="container-md">
              <div className="grey-pattern-full hover-box">
                <div className="content-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="vendor-payments-text">
                        <h3 className="size40">Vendor Payments</h3>
                        <p className="size20">
                          Directly pay your vendors swiftly and securely -
                          suppliers, cloud providers, ad services, travel
                          management companies, and utilities
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 vendorimg">
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={vendorPayoutImage}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={vendorPayoutImage}
                        ></source>
                        <img
                          className="desktop card-img hover-img"
                          alt="Vendor Payments"
                          src={vendorPayoutImage}
                        />
                      </picture>
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={vendorPayoutImage}
                        ></source>
                        <source
                          type="image/png"
                          srcSet={vendorPayoutImage}
                        ></source>
                        <img
                          className="mobile card-img hover-img"
                          alt="Vendor Payments"
                          src={vendorPayoutImage}
                        />
                      </picture>
                    </div>
                    <div className="cardcorner">
                      <span className="overflow-hidden">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet={cardCornerLazy}
                          ></source>
                          <source type="image/png" srcSet={cardCorner}></source>
                          <img
                            className="card-img hover-img"
                            alt="Vendor Payment"
                            src={cardCorner}
                          />
                        </picture>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="utilityPayments">
          <section id="get-paid" className="get-paid homegetpaid">
            <div className="container-md">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="directions">
                    <img src={directions} alt="Pay & Get Paid" />
                  </div>
                  <h2>Simplified Utility Payments</h2>
                  <p className="head-sub">Bill payments portal</p>
                </div>
              </div>
              <div className="gradient-card hover-box">
                <div className="inner-content">
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={overviewFrameLazy}
                    ></source>
                    <source type="image/png" srcSet={overviewFrame}></source>
                    <img
                      className="overview-frame desktop"
                      alt="Simplified Banking"
                      src={overviewFrame}
                    />
                  </picture>
                  <div className="columns-box">
                    <div className="row">
                      <div className="col-md-6">
                        <h3 className="size40">
                          Make all your Utility Payments Efficiently in One
                          Place
                        </h3>
                        <p className="size20">
                          Eliminate cumbersome manual processes for all your
                          bills including electricity, water, gas and more
                        </p>
                        <div className="action">
                          <Link className="button btn-white oca" to="/form">
                            Get started
                          </Link>
                          {/* <Link
                          className="button btn-white op25"
                          to="/products/current-account"
                        >
                          Know More
                        </Link> */}
                        </div>
                      </div>

                      <div className="col-md-4 text-center">
                        <picture>
                          <source
                            type="image/webp"
                            srcSet={overviewFrameLazy}
                          ></source>
                          <source
                            type="image/png"
                            srcSet={overviewFrame}
                          ></source>
                          <img
                            className="overview-frame mobile"
                            alt="Simplified Banking"
                            src={overviewFrame}
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div style={{ marginTop: 150 }}></div>

        {/* <section
          id="trusted-partners"
          className="trusted-partners purple-gradient-box"
        >
          <div className="container-md">
            <div className="inner-box">
              <div className="row justify-content-lg-center">
                <div className="col-lg-6 col-md-13 col-sm-12 text-center">
                  <h2 className="size40">Our Trusted Partners</h2>
                  <p className="size20">
                    Powering your financial journey with the backing of esteemed
                    partners: YES Bank, Unity Bank. Your business is in trusted
                    hands every step of the way
                  </p>
                  <div className="partners">
                    <img src={poweredByYesBank} alt="Powered By Yes Bank" />
                    <img src={poweredByUnity} alt="Powered By Unity Bank" />
                  </div>
                  <p class="text-white text-opacity-50 fst-italic">
                    more coming soon
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* 
        <section id="testemonials" className="prevent-select">
          <div className="slide-main">
            <div className="container-md">
              <div className="title text-center">
                <img
                  src={testemonialIcon}
                  alt="Reviews"
                />
                <h4>Here’s what out customers say</h4>
              </div>
              <OwlCarousel
                className="owl-theme"
                items={3}
                margin={25}
                dots={true}
                nav={true}
                onChanged={(e) => handleCarouselchanged(e)}
                ref={owlRef}
                responsiveClass={true}
                responsive={owlResponsiveOption}
              >
                <div className="slide first">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
        */}

        {/* <section id="available-on-mobile">
          <div className="container-md">
            <AvailableOnMobile></AvailableOnMobile>
          </div>
        </section> */}

        {/* 
        <section id="gradient-text-card" className="gradient-text-card text-center">
          <div className="container-md">
            <div className="row">
              <div className="col-md-4">
                <div className="value">71<span>%</span></div>
                <p>Improvement in efficiency for customers who switched to Tijoree Products</p>
              </div>
              <div className="col-md-4">
                <div className="value">5k+</div>
                <p>Businesses are currently using Tijoree Products</p>
              </div>
              <div className="col-md-4">
                <div className="value"><span>₹</span>12k</div>
                <p>Average annual savings for customers who switched to Tijoree's Current Account</p>
              </div>
            </div>
          </div>
        </section>
        */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Landing;
